import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {motion} from "framer-motion";
import {NavLink as RouterLink} from "react-router-dom";
import logo0FROG from "../assets/logo0FROG.png";
import ofrog from "../assets/frog.png";
import axios from "axios";
import {FaFileContract} from "react-icons/fa";
import {AirdropBanner, ReferralProgram} from './SharedComponents';
import {useTranslation} from "react-i18next"; // Import de i18n
import i18n from "i18next";
import bscLogo from "../assets/bsc.png"; // Ajouter un logo Binance Smart Chain
import { Link as ScrollLink } from "react-scroll";




// Wrapper pour la section héro avec un fond animé
const HeroWrapper = styled.section`
  margin-top: 70px; /* Correspond à la hauteur de la navbar */
  background: linear-gradient(135deg, #00a86b, #333333);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  padding: 80px 20px;
  position: relative;
  

  &::before {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.1), transparent);
    animation: rotateBG 15s linear infinite;
    z-index: -1;
  }

  @keyframes rotateBG {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @media (max-width: 768px) {
    padding: 50px 10px;
    margin-top: 60px; /* Ajusté pour mobile si nécessaire */
  }
`;

const BscInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  background: rgba(0, 0, 0, 0.8);
  color: #ffd700;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 1.2rem;
  gap: 10px;

  img {
    width: 30px;
    height: auto;
  }

  span {
    color: #00a86b;
    font-weight: bold;
  }
`;


// Bandeau d'alerte
const AlertBanner = styled.div`
  background-color: #ff7f50;
  color: white;
  text-align: center;
  padding: 1rem;
  font-size: 1.1rem;
  font-weight: bold;
  border-radius: 10px;
  margin: 1rem 0; /* Réduisez les marges */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  animation: slideIn 1s ease-out;

  a {
    color: #ffffff;
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;

    &:hover {
      color: #00ff88;
    }
  }

  @keyframes slideIn {
    from {
      transform: translateY(-50px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

const Navbar = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px; /* Hauteur fixe cohérente */
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 0, 0, 0.85);
  z-index: 999;
  box-sizing: border-box;

  @media (max-width: 768px) {
    height: 60px; /* Ajusté pour les écrans mobiles */
    padding: 0 0.5rem;
  }
`;

const PresalePriceInfo = styled.div`
  margin-top: 2rem;
  padding: 1rem 2rem;
  background: rgba(0, 0, 0, 0.8);
  color: #ffd700; /* Couleur dorée pour attirer l'attention */
  border-radius: 10px;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;

  span {
    color: #00ff88; /* Couleur verte pour les prix */
  }
`;


const HamburgerButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 20px;
  z-index: 1100;

  @media (min-width: 768px) {
    display: none; /* Caché sur les écrans larges */
  }

  div {
    width: 100%;
    height: 3px;
    background-color: white;
    border-radius: 5px;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }

  ${({ isOpen }) =>
          isOpen &&
          `
    div:nth-child(1) {
      transform: rotate(45deg) translate(5px, 5px);
    }
    div:nth-child(2) {
      opacity: 0;
    }
    div:nth-child(3) {
      transform: rotate(-45deg) translate(5px, -5px);
    }
  `}
`;

const NavLinkContainer = styled.div`
  display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
  flex-direction: column;
  gap: 1.5rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.95);
  align-items: center;
  justify-content: center;
  z-index: 1000;
  transition: all 0.3s ease-in-out;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    position: static;
    background-color: transparent;
    width: auto;
    height: auto;
    gap: 2rem;
    padding: 0;
  }
`;

const NavLink = styled.div`
  color: #ffd700;
  font-size: 1rem;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: #00a86b;
  }

  @media (max-width: 768px) {
    text-align: center; /* Centré sur mobile */
  }
`;

const RouterNavLink = styled(RouterLink)`
  color: #ffd700;
  font-size: 1rem;
  margin: 0 1rem;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: #00a86b;
  }
`;


// Bouton de Connexion Wallet
const WalletButton = styled.button`
  background: #ffd700;
  color: #1e293b;
  border: none;
  padding: 0.6rem 1.2rem;
  border-radius: 5px;
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s ease, color 0.3s ease;

  &:hover {
    background: #00a86b;
    color: white;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

// Container pour le logo et le modal
// Ajout des styles pour le container du logo et du modal
const LogoModalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
    margin-bottom: 1rem;
  }
`;

const LogoImage = styled(motion.img)`
  width: 300px; /* Ajustez la taille pour qu'elle soit proportionnelle */
  height: auto;
  margin-bottom: 2rem;
  margin-top: 20px;
  position: relative;
  z-index: 10; /* S'assure qu'il est au-dessus de l'animation de fond */

  animation: float 3s ease-in-out infinite; /* Ajout de l'animation de flottement */

  @keyframes float {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-20px);
    }
  }

  @media (max-width: 768px) {
    width: 200px;
    margin-bottom: 1rem;
  }
`;


const HeroTitle = styled(motion.h1)`
  font-size: 4rem;
  color: #FFD700;
  margin-bottom: 2rem;

  span {
    color: #00FF88;
  }

  @media (max-width: 768px) {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
`;

const SubTitle = styled(motion.p)`
  font-size: 1.5rem;
  color: #FFD700;
  margin-bottom: 3rem;
  max-width: 800px;
  text-align: center;
  line-height: 1.6;

  @media (max-width: 768px) {
    font-size: 1rem;
    margin-bottom: 1.5rem;
    max-width: 100%;
  }
`;

const StatsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Permet d'enrouler les éléments si nécessaire */
  gap: 2rem;
  margin-top: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
  }
`;


const StatBox = styled.div`
  color: #FFD700;
  text-align: center;

  h3 {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 1.2rem;
    color: #FFFFFF;
  }

  @media (max-width: 768px) {
    h3 {
      font-size: 2rem;
    }

    p {
      font-size: 1rem;
    }
  }
`;

const ContractLinks = styled.div`
  margin-top: 3rem;
  text-align: center;
  color: #ffd700;

  @media (max-width: 768px) {
    margin-top: 2rem;
    font-size: 0.9rem;
  }

  a {
    color: #ffd700;
    text-decoration: none;
    margin: 0 5px;
    font-weight: bold;
    display: inline-block; /* Empêche les débordements horizontaux */
    word-wrap: break-word; /* Permet de découper les longues chaînes si nécessaire */

    &:hover {
      color: #00a86b;
    }
  }
`;

const LanguageButtonContainer = styled.div`
  display: flex;
  gap: 10px;

  button {
    background: none;
    border: none;
    padding: 5px;
    cursor: pointer;
    transition: transform 0.2s ease, opacity 0.3s ease;

    &:hover {
      transform: scale(1.2);
    }

    &:focus {
      outline: none;
    }

    img {
      display: block;
      border-radius: 3px; /* Coins légèrement arrondis pour un style élégant */
    }
  }
`;

const LanguageToggle = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  button {
    background: none;
    border: none;
    cursor: pointer;

    img {
      width: 20px;
      height: auto;
    }

    &:hover {
      transform: scale(1.1);
    }
  }
`;

const NavLinks = styled.div`
  /* Mobile styles */
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
  transform: ${({ isOpen }) => (isOpen ? "translateX(0)" : "translateX(-100%)")};
  transition: all 0.3s ease;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1e293b;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  /* Desktop styles */
  @media (min-width: 768px) {
    opacity: 1; /* Toujours visible sur desktop */
    visibility: visible;
    transform: translateX(0); /* Supprime l'effet de glissement */
    position: static; /* Remet le menu en position normale */
    width: auto;
    height: auto;
    background-color: transparent; /* Pas de fond pour desktop */
    flex-direction: row; /* Les éléments sont alignés horizontalement */
    gap: 2rem;
  }
`;


const DecorativeFrog = styled.img`
  position: absolute;
  bottom: 20px;
  right: 20px; /* Position en bas à droite */
  width: 120px;
  height: auto;
  animation: bounce 3s infinite; /* Animation de rebond */
  z-index: 10;

  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-15px);
    }
  }

  @media (max-width: 768px) {
    width: 90px; /* Ajustement pour les petits écrans */
    bottom: 10px;
    right: 10px;
  }
`;

const HeroSection = () => {
    const { t } = useTranslation(); // Traductions
    const [tokensSold, setTokensSold] = useState(0); // Total des tokens vendus
    const [activeWallets, setActiveWallets] = useState(0); // Wallets actifs (whitelistés)
    const [walletAddress, setWalletAddress] = useState(null); // Adresse du portefeuille connecté
    const [isMenuOpen, setIsMenuOpen] = useState(false); // État du menu hamburger
    const [currentRate, setCurrentRate] = useState(0); // Taux actuel (1 BNB = ? tokens)
    const [bnbToEuroRate] = useState(607.95); // Conversion BNB > Euro (par exemple)
    const [isPresaleStarted, setIsPresaleStarted] = useState(false); // Statut prévente

    // Calcul du prix approximatif en euros
    const tokenPriceInEuro = (1 / currentRate) * bnbToEuroRate || 0;

    const toggleMenu = () => setIsMenuOpen((prev) => !prev);
    const handleNavLinkClick = () => setIsMenuOpen(false);

    const redirectToMetaMask = () => {
        const dappUrl = "ofrog.xyz/dashboard"; // Votre URL
        window.location.href = `https://metamask.app.link/dapp/${dappUrl}`;
    };

    const detectWalletProvider = () => {
        if (typeof window.ethereum !== "undefined") {
            if (window.ethereum.isMetaMask) {
                return "MetaMask";
            }
            return "Ethereum-compatible browser";
        }

        // Vérification pour les deep links vers MetaMask mobile
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/android|iphone|ipad|ipod/i.test(userAgent)) {
            return "Mobile";
        }

        return "NoProvider";
    };

    // Charger les données de la prévente
    useEffect(() => {
        const fetchPresaleData = async () => {
            try {
                // Récupérer les données de la prévente depuis le backend
                const tokensResponse = await axios.get('/api/presale-total');
                console.log('Tokens Sold Response:', tokensResponse.data);

                const walletsResponse = await axios.get('/api/active-wallets');
                console.log('Active Wallets Response:', walletsResponse.data);

                const rateResponse = await axios.get('/api/getCurrentRate');
                console.log('Current Rate Response:', rateResponse.data);

                const statusResponse = await axios.get('/api/presale-status');
                console.log('Presale Status Response:', statusResponse.data);

                // Mettre à jour les états
                setTokensSold(tokensResponse.data.total_tokens_sold || 0);
                setActiveWallets(walletsResponse.data.active_wallets || 0);
                setCurrentRate(rateResponse.data.currentRate || 0);
                setIsPresaleStarted(statusResponse.data.isPresaleStarted || false);
            } catch (error) {
                console.error("Erreur lors de la récupération des données de la prévente :", error);
            }
        };

        fetchPresaleData();
    }, []);

    // Connexion au portefeuille MetaMask avec vérification du réseau BSC
    const connectWallet = async () => {
        const providerType = detectWalletProvider();

        if (providerType === "MetaMask") {
            try {
                // Demande les comptes disponibles
                const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });

                if (accounts.length > 0) {
                    const wallet = accounts[0];
                    setWalletAddress(wallet);

                    // Vérifie si l'utilisateur est sur le réseau Binance Smart Chain
                    const chainId = await window.ethereum.request({ method: "eth_chainId" });
                    const bscMainnetChainId = "0x38"; // ID du réseau Binance Smart Chain (Mainnet)

                    if (chainId !== bscMainnetChainId) {
                        // Demande de basculer sur le réseau BSC
                        try {
                            await window.ethereum.request({
                                method: "wallet_switchEthereumChain",
                                params: [{ chainId: bscMainnetChainId }],
                            });
                            console.log("Changé sur Binance Smart Chain.");
                        } catch (switchError) {
                            // Si l'utilisateur refuse ou si le réseau n'existe pas dans MetaMask
                            if (switchError.code === 4902) {
                                alert("Veuillez ajouter manuellement Binance Smart Chain à MetaMask.");
                            } else {
                                console.error("Erreur lors du changement de réseau :", switchError);
                            }
                            return;
                        }
                    }

                    // Enregistrement du wallet dans le backend
                    await axios.post("/api/wallets", { walletAddress: wallet });
                    console.log("Wallet connecté :", wallet);
                } else {
                    alert("Aucun compte MetaMask détecté.");
                }
            } catch (error) {
                console.error("Erreur lors de la connexion au wallet :", error);
                alert(`Erreur de connexion : ${error.message}`);
            }
        } else if (providerType === "Mobile") {
            redirectToMetaMask();
        } else {
            alert("MetaMask n'est pas détecté. Veuillez l'installer pour continuer.");
        }
    };



    const disconnectWallet = () => {
        setWalletAddress(null);
        console.log("Wallet déconnecté");
    };

    const handleWalletButtonClick = () => {
        if (walletAddress) {
            disconnectWallet();
        } else {
            connectWallet();
        }
    };

    return (
        <HeroWrapper id="hero">
            <Navbar>
                <HamburgerButton onClick={toggleMenu}>
                    <div />
                    <div />
                    <div />
                </HamburgerButton>

                <NavLinks isOpen={isMenuOpen}>
                    <ScrollLink
                        to="hero"
                        smooth={true}
                        duration={800}
                        offset={-70}
                        style={{
                            color: "#FFD700",
                            textDecoration: "none",
                            cursor: "pointer",
                            transition: "color 0.3s ease",
                        }}
                        onClick={toggleMenu}
                    >
                        {t("navigation.hero")}
                    </ScrollLink>
                    <ScrollLink
                        to="tokenomics"
                        smooth={true}
                        duration={800}
                        offset={-70}
                        style={{
                            color: "#FFD700",
                            textDecoration: "none",
                            cursor: "pointer",
                            transition: "color 0.3s ease",
                        }}
                        onClick={toggleMenu}
                    >
                        {t("navigation.tokenomics")}
                    </ScrollLink>
                    <ScrollLink
                        to="roadmap"
                        smooth={true}
                        duration={800}
                        offset={-70}
                        style={{
                            color: "#FFD700",
                            textDecoration: "none",
                            cursor: "pointer",
                            transition: "color 0.3s ease",
                        }}
                        onClick={() => console.log("[ScrollLink] Clicked on Roadmap link")}
                        onSetActive={() => console.log("[ScrollLink] Roadmap is now active.")}
                        onSetInactive={() => console.log("[ScrollLink] Roadmap is now inactive.")}
                    >
                        {t("navigation.roadmap")}
                    </ScrollLink>
                    <ScrollLink
                        to="faq"
                        smooth={true}
                        duration={800}
                        offset={-70}
                        style={{
                            color: "#FFD700",
                            textDecoration: "none",
                            cursor: "pointer",
                            transition: "color 0.3s ease",
                        }}
                        onClick={toggleMenu}
                    >
                        {t("navigation.faq")}
                    </ScrollLink>

                    {/* Lien pour les pages utilisant react-router-dom */}
                    <RouterNavLink to="/dashboard" onClick={toggleMenu}>
                        {t("navigation.dashboard")}
                    </RouterNavLink>
                    <RouterNavLink to="/whitepaper" onClick={toggleMenu}>
                        {t("navigation.whitepaper")}
                    </RouterNavLink>
                </NavLinks>

                <WalletButton onClick={handleWalletButtonClick}>
                    {walletAddress ? `${t("wallet.disconnect")} (${walletAddress})` : t("wallet.connect")}
                </WalletButton>

                <LanguageToggle>
                    <button onClick={() => i18n.changeLanguage("en")} aria-label={t("aria.switch_to_english")}>
                        <img src="https://flagcdn.com/w40/gb.png" alt="English" />
                    </button>
                    <button onClick={() => i18n.changeLanguage("fr")} aria-label={t("aria.switch_to_french")}>
                        <img src="https://flagcdn.com/w40/fr.png" alt="Français" />
                    </button>
                    <button onClick={() => i18n.changeLanguage("sp")} aria-label={t("aria.switch_to_spanish")}>
                        <img src="https://flagcdn.com/w40/es.png" alt="Espagnol" />
                    </button>
                </LanguageToggle>
            </Navbar>

            <LogoModalContainer>
                <LogoImage src={logo0FROG} alt={t("aria.logo_alt")} />
            </LogoModalContainer>

            {activeWallets < 1000 ? (
                <AlertBanner>
                    🚨 <a onClick={connectWallet}>{t("alert.message", { remaining: 1000 - activeWallets })}</a>
                </AlertBanner>
            ) : isPresaleStarted ? (
                <AlertBanner>
                    🚀 {t("presale.info", {
                    rate: currentRate > 0 ? currentRate : t("presale.calculating_rate"),
                    price: currentRate > 0 ? tokenPriceInEuro.toFixed(6) : t("presale.calculating_price")
                })}
                </AlertBanner>
            ) : (
                <AlertBanner>
                    {t("alert.presale_ready")}
                </AlertBanner>
            )}

            <HeroTitle>
                {t("hero_section.title", { token: "FrogoCoin" })}
            </HeroTitle>

            <BscInfo>
                <img src={bscLogo} alt="Binance Smart Chain Logo" />
                {t("bsc_info.message")}
            </BscInfo>

            <SubTitle>{t("hero_section.subtitle")}</SubTitle>

            <StatsContainer>
                <StatBox>
                    <h3>{tokensSold.toLocaleString()}</h3>
                    <p>{t("hero_section.stats.tokens_sold")}</p>
                </StatBox>
                <StatBox>
                    <h3>{activeWallets !== undefined && activeWallets !== null ? activeWallets.toLocaleString() : "..."}</h3>
                    <p>{t("hero_section.stats.wallets_whitelisted")}</p>
                </StatBox>
                <StatBox>
                    <h3>50%</h3>
                    <p>{t("hero_section.stats.liquidity_locked")}</p>
                </StatBox>
            </StatsContainer>

            <ReferralProgram>
                <h3>{t("referral_program.title")}</h3>
                <p>{t("referral_program.message")}</p>
                <p>{t("referral_program.bonus")}</p>
            </ReferralProgram>

            <AirdropBanner>
                {t("airdrop.banner")}
            </AirdropBanner>

            <ContractLinks>
                <p>
                    <FaFileContract /> {t("contracts.links_title")}
                </p>
                <a href="https://bscscan.com/address/0x24d506848912EB60bC01434693D1Cd023F0C33cb" target="_blank" rel="noopener noreferrer">
                    {t("contracts.main_contract")}
                </a>
                <a href="https://bscscan.com/address/0xE6DCB7110F966DEDBb3df7Cb25C665a327ea1323" target="_blank" rel="noopener noreferrer">
                    {t("contracts.lock_contract")}
                </a>
            </ContractLinks>
            <DecorativeFrog src={ofrog} alt="Grenouille décorative" />
        </HeroWrapper>
    );
};

export default HeroSection;

