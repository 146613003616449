import React from 'react';
import styled from 'styled-components';
import { FaTwitter, FaDiscord, FaFileContract } from 'react-icons/fa';
import { useTranslation } from 'react-i18next'; // Import du hook pour les traductions

const FooterWrapper = styled.footer`
  background-color: #333333;
  color: white;
  padding: 3rem 1rem;
  text-align: center;
  border-top: 1px solid #FFD700;
`;

const SocialIcons = styled.div`
  margin-bottom: 1.5rem;

  a {
    margin: 0 15px;
    color: #FFD700;
    font-size: 2rem;
    text-decoration: none;

    &:hover {
      color: #00A86B;
    }
  }
`;

const ContractLinks = styled.div`
  margin: 1.5rem 0;
  font-size: 1.2rem;
  color: #FFD700;

  a {
    margin: 0 10px;
    color: #FFD700;
    text-decoration: none;
    font-weight: bold;

    &:hover {
      color: #00A86B;
    }
  }
`;

const Copyright = styled.p`
  font-size: 1rem;
  margin-top: 2rem;
  color: #FFD700;
`;

const Footer = () => {
    const { t } = useTranslation(); // Initialisation de la fonction de traduction

    return (
        <FooterWrapper>
            <SocialIcons>
                <a href="https://discord.gg/gw4R9wYf" target="_blank" rel="noopener noreferrer" aria-label={t("footer.discord")}>
                    <FaDiscord />
                </a>
            </SocialIcons>
            <ContractLinks>
                <p>
                    <FaFileContract /> {t("footer.contract_links_title")}
                </p>
                <a
                    href="https://bscscan.com/address/0x24d506848912EB60bC01434693D1Cd023F0C33cb"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {t("footer.main_contract")}
                </a>
                <a
                    href="https://bscscan.com/address/0xE6DCB7110F966DEDBb3df7Cb25C665a327ea1323"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {t("footer.lock_contract")}
                </a>
            </ContractLinks>
            <Copyright>
                © 2024 FrogoCoin. {t("footer.copyright")}
            </Copyright>
        </FooterWrapper>
    );
};

export default Footer;
